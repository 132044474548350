import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface AppMenuItemInterface
{
    title: string;
    child?: AppMenuItemInterface[];
    route?: string;
    hidden?: boolean;
    toggled?: boolean;
    icon?: IconProp;
}

export const APP_LINK_MENU: AppMenuItemInterface[] = [
    {
        title: 'Dashboard',
        icon: 'chart-line',
        route: '/dashboard'
    },
    {
        title: 'Sales',
        icon: 'id-card-alt',
        child: [
            {
                title: 'Orders',
                child: [

                    {
                        title: 'List',
                        route: '/sales/orders'
                    },
                    {
                        title: 'Unpaid',
                        route: '/crm/unpaid-orders',
                    },
                    {
                        title: 'Seller orders',
                        route: '/sales/seller-orders',
                    },
                    {
                        title: 'Not Dropshipped',
                        route: '/sales/not-dropshipped',
                    },
                ]
            },
            {
                title: 'Returns',
                child: [
                    {
                        title: 'List',
                        route: '/sales/returns/',
                    },
                    {
                        title: 'Investigate',
                        route: '/sales/returns-investigate',
                    },
                ]
            },
            {
                title: 'Document',
                child: [
                    {
                        title: 'Invoices',
                        route: '/sales/invoices'
                    },
                    {
                        // TODO remove https://boodmo.atlassian.net/browse/BOODMO-39933 ?
                        title: 'Credit Notes',
                        route: '/sales/credit-notes'
                    },
                    {
                        title: 'MP Debit Notes',
                        route: '/sales/debit-notes'
                    },
                ]
            },
            {
                title: 'Customers',
                route: '/crm/customers'
            },
            {
                title: 'Payments',
                route: '/crm/payments',
            },
            {
                title: 'Refunds',
                route: '/crm/refunds',
            },
        ],
    },
    {
        title: 'Master data',
        icon: 'wrench',
        child: [
            {
                title: 'Catalog',
                child: [
                    {
                        title: 'Attributes',
                        route: '/pim/attributes'
                    },
                    {
                        title: 'Classification',
                        route: '/pim/classes'
                    },
                    {
                        title: 'Brands',
                        route: '/pim/brands'
                    },
                    {
                        title: 'Locations',
                        route: '/pim/locations'
                    },
                    {
                        title: 'Categories',
                        route: '/pim/categories'
                    },
                    {
                        title: 'Parts',
                        route: '/pim/parts'
                    },
                    {
                        title: 'Smart grid',
                        route: '/pim/smart-grid'
                    },
                ]
            },
            {
                title: 'Import',
                route: '',
                child: [
                    {
                        title: 'Part',
                        route: '/pim/part-import'
                    },
                    {
                        title: 'Replicas',
                        route: '/pim/replicas-import'
                    },
                    {
                        title: 'Merge Parts',
                        route: '/pim/merge-parts-import'
                    },
                    {
                        title: 'Clear replacement link',
                        route: '/pim/replacement-import'
                    },
                    {
                        title: 'Attribute',
                        route: '/pim/attribute-import'
                    },
                    {
                        title: 'MRP Tool',
                        route: '/pim/mrp-tool'
                    },
                ]
            },
            {
                title: 'Vehicles',
                child: [
                    {
                        title: 'Vehicle Catalog',
                        route: '/pim/vehicles',
                        child: []
                    },
                    {
                        title: 'Number Plates',
                        route: '/pim/number-plates',
                    },
                ]
            },
            {
                title: 'Import',
                route: '',
                hidden: true,
                child: [
                    {
                        title: 'Parts',
                        route: '/pim/import-parts',
                        hidden: true,
                    },
                    {
                        title: 'Replicas',
                        route: '/pim/import-replicas',
                        hidden: true,
                    },
                    {
                        title: 'Applicability',
                        route: '/pim/import-applicability',
                        hidden: true,
                    },
                ]
            },
        ],
    },
    {
        title: 'SRM',
        icon: 'arrows-alt-h',
        child: [
            {
                title: 'Seller',
                route: '/srm/sellers',
            },
            {
                title: 'Commission & Fees',
                route: '/sales/commission_rules'
            },
            {
                title: 'Replacements',
                route: '/sales/replacements',
            },
            {
                title: 'Price calculator',
                route: '/srm/price-calculator'
            },
            {
                title: 'Open Hub',
                route: '/srm/open-hub'
            },
            {
                title: 'Unlinked Offers',
                route: '/srm/unlinked-offers'
            },
        ],
    },
    {
        title: 'Logistic',
        icon: 'exchange-alt',
        child: [
            {
                title: 'Forward',
                route: '/logistic/forward-logistics',
            },
            {
                title: 'Reverse',
                route: '/logistic/backward-logistics',
            },
            {
                title: 'Claims',
                route: '/logistic/claims',
            },
            {
                title: 'PIN codes',
                route: '/system/pins',
            },
            {
                title: 'Carriers',
                route: '/logistic/carriers',
            },
            {
                title: 'Calculations',
                child: [
                    {
                        title: 'Delivery settings',
                        route: '/system/delivery-settings',
                        hidden: true,
                    },
                    {
                        title: 'Shipping rates',
                        route: '/system/shipping-rates',
                    },
                    {
                        title: 'Regional coefficients',
                        route: '/system/regional-coefficients',
                    },
                    {
                        title: 'Product class adjustments',
                        route: '/system/product-class-adjustments',
                    },
                    {
                        title: 'Product brand adjustments',
                        route: '/system/product-brand-adjustments',
                    },
                    {
                        title: 'Delivery Promo Rules',
                        route: '/system/delivery-promo-rules'
                    },
                    {
                        title: 'Transport schedule',
                        route: '/logistic/transport-schedule'
                    }
                ]
            },
            {
                title: 'Tasks',
                route: '/logistic/tasks',
            },
        ],
    },

    {
        title: 'Kiosks',
        icon: 'store',
        child: [
            {
                title: 'List',
                route: '/logistic/kiosks',
            },
            {
                title: 'Minihub manifest',
                route: '/logistic/minihub-manifest',
            },
        ]
    },
    {
        title: 'Finance',
        icon: 'file-invoice-dollar',
        child: [
            {
                title: 'Channels',
                route: '/finance/channel',
            },
            {
                title: 'Payouts',
                route: '/finance/payouts',
            },
            {
                title: 'Withdraw',
                route: '/finance/withdraw',
            },
            {
                title: 'Transactions',
                route: '/finance/transactions',
            },
            {
                title: 'GW Settlements',
                route: '',
                child: [
                    {
                        title: 'List',
                        route: '/finance/settlements',
                    },
                    {
                        title: 'Investigate',
                        route: '/finance/investigate',
                    },
                ],
            },
            {
                title: 'Accounts',
                route: '/finance/account',
                hidden: true,
            },
            {
                title: 'Tasks',
                route: '/finance/tasks',
            },
        ],
    },
    {
        title: 'Service Billing',
        icon: 'rupee-sign',
        child: [
            {
                title: 'History Usage',
                route: '/billing/history-usage',
            },
            {
                title: 'Tariff Plans',
                route: '/billing/tariff-plans',
            },
            {
                title: 'Reward Rules',
                route: '/billing/reward-rules',
            },
            {
                title: 'Recharge Bundles',
                route: '/billing/recharge-bundles',
            },
        ],
    },
    {
        title: 'General',
        icon: 'cogs',
        child: [
            {
                title: 'System',
                route: '',
                child: [
                    {
                        title: 'Admins',
                        route: '/system/users'
                    },
                    {
                        title: 'Fulfillment centers',
                        route: '/logistic/fulfillment-centers'
                    },
                    {
                        title: 'Hub',
                        route: '/logistic/hubs'
                    },
                    {
                        title: 'Configuration',
                        route: '/system/configuration',
                        hidden: true,
                    },
                    {
                        title: 'Payment Methods',
                        route: '/system/payment-method',
                    },
                    {
                        title: 'Crons',
                        route: '/system/cron_jobs'
                    },
                    {
                        title: 'Status',
                        route: '/system/status',
                        hidden: true,
                    },
                    {
                        title: 'FIQK',
                        route: '/system/fiqk'
                    },
                ]
            },
            {
                title: 'CMS',
                route: '',
                child: [
                    {
                        title: 'Banners',
                        route: '/pim/banners'
                    },
                    {
                        title: 'Pages',
                        route: '/pim/pages'
                    },
                    {
                        title: 'SEO',
                        route: '/pim/seo',
                        hidden: true,
                    },
                ]
            },
            {
                title: 'Settings',
                route: '',
                child: []
            },
        ],
    },
];
